define('formation-ember-components/components/checkbox-field/component', ['exports', 'formation-ember-components/components/checkbox-field/template', 'formation-ember-components/mixins/form-inputs', 'formation-ember-components/mixins/generate-input-id'], function (exports, _template, _formInputs, _generateInputId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CheckboxField = Ember.Component.extend(_formInputs.default, _generateInputId.default, {
    layout: _template.default,
    classNames: 'checkbox-field'
  });

  CheckboxField.reopenClass({
    positionalParams: ['field']
  });

  exports.default = CheckboxField;
});