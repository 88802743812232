define('formation-ember-components/components/text-field/component', ['exports', 'formation-ember-components/components/text-field/template', 'formation-ember-components/mixins/form-inputs', 'formation-ember-components/mixins/generate-input-id'], function (exports, _template, _formInputs, _generateInputId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * A `text-field` component is used to display a single-line text input field.
   *
   * This component can be used by itself, with the following template format:
   *
   * ```hbs
   * {{text-field "hed" model=model placeholder="Enter hed here" noLabel=true characterCount=false}}
   * ```
   *
   * The invocation above will update the value of the model's `hed` property directly using
   * two-way bindings.
   *
   * This component can be rendered within the `copilot-form` component similarly:
   *
   * ```hbs
   * {{#copilot-form model=model as |f|}}
   *   {{f.text-field "name" placeholder="New Employee" characterCount=true}}
   * {{/copilot-form}}
   * ```
   *
   * @module copilot/components/text-field/component
   * @augments Ember.Component
   * @mixes copilot/mixins/copilot-form-inputs
   * @mixes copilot/mixins/generate-input-id
   * @param {String} field - The attribute on the model that represents the
   * text value. This is a positional param.
   * @param {Object} model - The object to fetch the field from. Required when the
   * component is not invoked as a `copilot-form` contextual component.
   * @param {String} placeholder - An optional parameter to display a custom placeholder text
   * @param {String} characterCount - An optional parameter to display the number of
   * characters on the field
   */
  var TextField = Ember.Component.extend(_formInputs.default, _generateInputId.default, {
    layout: _template.default,
    classNames: ['text-field form-group'],
    classNameBindings: ['validation.messages.length:has-error:'],
    autofocus: false,

    actions: {
      inputChange: function inputChange(value) {
        var _this = this;

        Ember.run(function () {
          _this.set('fieldValue', value);
        });
      }
    },

    focusOut: function focusOut() {
      var value = this.get('fieldValue');
      if (value) {
        this.set('fieldValue', value.replace(/^\s\s*/, '').replace(/\s\s*$/, ''));
      }
    },
    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        this.sendAction.bind(this)();
      }
    },

    showValidationMessage: function () {
      if (this.get('showMessage') === false) {
        return false;
      }
      return this.get('validation.messages.length');
    }.property('showMessage', 'validation.messages.length'),

    computedSize: function () {
      return this.get('size') || 20;
    }.property('size'),

    computedMaxLength: function () {
      return this.get('maxlength') || 524288;
    }.property('maxlength'),

    autofocusField: function () {
      if (this.get('autofocus')) {
        this.$('input').focus();
      }
    }.on('didInsertElement')
  });

  TextField.reopenClass({
    positionalParams: ['field']
  });

  exports.default = TextField;
});