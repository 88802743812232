define('formation-ember-components/components/copy-count/component', ['exports', 'formation-ember-components/components/copy-count/template', 'npm:@condenast/copilot-markdown-shim', 'npm:@condenast/copilot-markdown-shim-sanitize', 'npm:lodash'], function (exports, _template, _copilotMarkdownShim, _copilotMarkdownShimSanitize, _npmLodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _copilotMarkdownShim.default.use(_copilotMarkdownShimSanitize.default);

  var computed = Ember.computed;
  var sanitize = _copilotMarkdownShim.default.sanitize;
  exports.default = Ember.Component.extend({
    classNames: ['copy-count'],
    layout: _template.default,

    characterCounter: computed('text', function () {
      var text = this.get('text');
      text = sanitize(text);
      return text.length;
    }),

    wordCounter: computed('text', function () {
      var text = this.get('text');
      text = sanitize(text, {
        stripEmbeds: true
      });

      return _npmLodash.default.compact(text.trim().split(' ')).length;
    })

  });
});