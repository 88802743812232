define('formation-ember-components/mixins/form-inputs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var _Ember$String = Ember.String,
      decamelize = _Ember$String.decamelize,
      dasherize = _Ember$String.dasherize;
  exports.default = Ember.Mixin.create(
  /** @lends copilot/mixins/copilot-form-inputs */
  {
    i18n: Ember.inject.service(),

    /**
     * Whether to skip rendering of the component's label
     *
     * @param
     * @type {Boolean}
     * @default false
     */
    noLabel: false,

    /**
     * A value to override the label that would otherwise be automatically
     * extracted from the component's `field` property
     *
     * @param
     * @type {String}
     * @default null
     */
    customLabel: null,

    /**
     * Specify the language key default placeholder text, when {@property placeholder} is not specified.
     * @param
     * @type {String|Null}
     * @default "placeholder.text"
     */
    defaultPlaceholder: 'placeholder.text',

    classNameBindings: ['gridItemClass', 'data-page-object-selector'],
    attributeBindings: 'data-page-object-selector',
    'data-page-object-selector': computed('field', function () {
      var field = this.get('field');
      field = decamelize(field.replace('.', '-')).split('_').join('-').split('.').join('-');
      var type = this.get('model.type');
      return type ? type + '-' + field : field;
    }),

    /**
     * A custom grid Item class parameter. This should be a applied at the
     * component's invocation site.
     *
     * @param
     * @type {String}
     */
    customGridItemClass: null,

    /**
     * A value that can be used to override the key in the model's
     * `validations.messages` object from which validations for the component
     * will be extracted.
     *
     * @param
     * @type {String}
     * @default null
     */
    validationOverride: null,

    gridItemClass: computed('customGridItemClass', function () {
      return this.get('customGridItemClass');
    }),

    computedLabel: computed('field', 'customLabel', function () {
      if (this.get('customLabel')) {
        return this.get('customLabel');
      }
      var field = this.get('field');
      return field;
    }),

    labelText: computed('computedLabel', 'noLabel', function () {
      if (this.get('noLabel')) {
        return null;
      }
      return this.get('computedLabel');
    }),

    /**
     * Property used **internally** in a component to render a proper placeholder text, user overrides shall be
     * expressed via {@param placeholder}.
     * @override copilot/mixins/copilot-form-inputs#fieldPlaceholder
     * Override to localize field placeholder text, falling back to localized text label.
     */
    fieldPlaceholder: computed('computedLabel', 'placeholder', function () {
      var i18n = this.get('i18n');
      var label = this.get('computedLabel');
      // Use localized placeholder if provided.
      var placeholder = this.get('placeholder');
      if (placeholder) {
        return placeholder;
      } else {
        // Use key from invocation or default
        var key = this.get('placeholderKey') || this.get('defaultPlaceholder');

        // Use localized label (via customLabel) if provided.
        var labelTranslated = i18n.t('fieldNames.' + label, { fallbackToKey: true });
        var labelText = labelTranslated && labelTranslated.toString() || label;
        return i18n.t(key, {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/toLocaleLowerCase
          labelText: labelText.toLocaleLowerCase()
        });
      }
    }),

    validationField: computed('validationOverride', 'field', function () {
      var validationOverride = this.get('validationOverride');
      return validationOverride ? validationOverride : this.get('field');
    }),

    title: computed('field', 'customLabel', function () {
      var text = this.get('customLabel') || this.get('field');
      return dasherize(text.toString());
    })
  });
});