define('formation-ember-components/components/cp-t/component', ['exports', 'formation-ember-components/components/cp-t/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    i18n: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      Ember.defineProperty(this, 'translatedString', computed('path', 'hash', function () {
        var path = this.get('path');
        var hash = this.get('hash');
        return this.get('i18n').t(path, hash);
      }));
    }
  }).reopenClass({
    positionalParams: ['path']
  });
});