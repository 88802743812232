define('hbs-module-transform/helpers/t', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.t = t;


  // A dummy `t` helper to use in the dummy app during tests
  function t(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(t);
});