define('formation-ember-components/components/password-field/component', ['exports', 'formation-ember-components/components/password-field/template', 'formation-ember-components/mixins/form-inputs', 'formation-ember-components/mixins/generate-input-id'], function (exports, _template, _formInputs, _generateInputId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PasswordField = Ember.Component.extend(_formInputs.default, _generateInputId.default, {
    layout: _template.default,
    classNames: ['form-group'],
    classNameBindings: ['validation.messages.length:has-error:'],
    defaultPlaceholder: 'placeholder.password',
    focusOut: function focusOut() {
      var value = this.get('fieldValue');
      if (value) {
        this.set('fieldValue', value.replace(/^\s\s*/, '').replace(/\s\s*$/, ''));
      }
    },
    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        this.sendAction.bind(this)();
      }
    }
  });

  PasswordField.reopenClass({
    positionalParams: ['field']
  });

  exports.default = PasswordField;
});