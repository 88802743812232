define('formation-ember-components/helpers/guid-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.guidFor = guidFor;
  function guidFor(params) {
    return Ember.guidFor(params[0]);
  }

  exports.default = Ember.Helper.helper(guidFor);
});