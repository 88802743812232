define('ember-overlays/components/overlay-marks', ['exports', 'ember', 'ember-overlays/templates/components/overlay-marks'], function (exports, _ember, _emberOverlaysTemplatesComponentsOverlayMarks) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberOverlaysTemplatesComponentsOverlayMarks['default'],
    tagName: '',
    service: _ember['default'].inject.service('ember-overlays'),
    marks: _ember['default'].computed('service.marks', function () {
      var group = this.get('group') || 'default';
      var id = this.get('id');
      return this.get('service.marks').filter(function (m) {
        return m.group === group && (id == null || m.id === id);
      });
    })
  });
});