define('formation-ember-components/helpers/capitalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var capitalize = Ember.String.capitalize;
  exports.default = Ember.Helper.helper(function (words) {
    return capitalize(words.join(''));
  });
});