define('ember-overlays/components/mark-overlay', ['exports', 'ember', 'ember-overlays/templates/components/mark-overlay', 'ember-overlays/mark-info', 'ember-overlays/ember-private-api'], function (exports, _ember, _emberOverlaysTemplatesComponentsMarkOverlay, _emberOverlaysMarkInfo, _emberOverlaysEmberPrivateApi) {
  var guidFor = _ember['default'].guidFor;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberOverlaysTemplatesComponentsMarkOverlay['default'],
    tagName: '',
    service: _ember['default'].inject.service('ember-overlays'),

    // Addon authors should group their overlays under a unique group
    // name so they don't interfere with other uses. An app can use the
    // default group.
    group: 'default',

    // this is an optional parameter that will be made available as
    // mark.model inside overlay-marks
    model: null,

    didRender: function didRender() {
      this.get('service').registerMark(guidFor(this), this.info());
    },

    willDestroyElement: function willDestroyElement() {
      this.get('service').unregisterMark(guidFor(this));
    },

    info: function info() {
      var _componentNodes = (0, _emberOverlaysEmberPrivateApi.componentNodes)(this);

      var firstNode = _componentNodes.firstNode;
      var lastNode = _componentNodes.lastNode;

      return new _emberOverlaysMarkInfo['default'](this.get('id'), firstNode, lastNode, this.get('group') || 'default', this.get('model'));
    }

  });
});