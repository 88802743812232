define('copilot-logger-service/services/logger', ['exports', 'ic-ajax', 'ember', 'npm:lodash'], function (exports, _icAjax, _ember, _npmLodash) {
  'use strict';

  exports.__esModule = true;

  /*
  // Sample usage:
  // Takes two arguments, log message, and an object literal
  // containing custom data, is is also injected into the Ember App
  
  var log = require('loggerService');
  
  // or
  
  var log = this.get('loggerService');
  
  // Calling the logger
  
  log.info('info message to send', {});
  log.warn('warning message to send', {});
  log.error('error message to send', {caught: error.stack});
  */

  // TODO: @mbenin extend logger to check args and take one argument

  var env = _ember.default.$.cookie('env'); /* global Rollbar */
  // import Rollbar from 'rollbar';

  var debug = env !== 'staging' && env !== 'production';

  exports.default = _ember.default.Service.extend({

    _userProperties: function () {

      var user = this.get('userService');
      if (user && user.get('isAuthenticated')) {
        return {
          loggedIn: 'true',
          brand: user.get('activeBrand'),
          env: env,
          uid: user.get('uid'),
          user: user.get('fullName'),
          email: user.get('email'),
          client_url: window.location.href
        };
      }

      return {
        loggedIn: 'false',
        env: env,
        client_url: window.location.href
      };
    },

    _createLogData: function (level, message, caught) {
      var props = this._userProperties();
      caught = caught && JSON.parse(JSON.stringify(caught));
      return _npmLodash.default.extend({}, props, {
        level: level,
        msg: message,
        caught: caught
      });
    },

    _sendLogData: function (data) {
      var level = data.level;

      if (level !== 'error' && window.Rollbar && window.Rollbar[level]) {
        window.Rollbar[level](data.msg, data.caught);
      }

      if (debug && level !== 'error') {
        console.log(level + ': ' + data.msg);
        console.log(data.caught);
      }

      (0, _icAjax.default)('/log', {
        data: data,
        type: 'POST'
      }).then(function (response) {
        if (window.Rollbar) {
          window.Rollbar.info('Log sent to Express App', response);
        }
      });
    },

    info: function (message, customData) {
      var data = this._createLogData('info', message, customData);
      this._sendLogData(data);
    },

    warn: function (message, customData) {
      var data = this._createLogData('warn', message, customData);
      this._sendLogData(data);
    },

    error: function (message, customData, error) {
      var data = this._createLogData('error', message, customData);
      this._sendLogData(data);
      if (error && window.Rollbar) {
        window.Rollbar.error(error);
      }
    }

  });
});