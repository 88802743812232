define('ember-overlays/scrim-fader', ['exports', 'rsvp', 'jquery', 'liquid-fire'], function (exports, _rsvp, _jquery, _liquidFire) {
  exports['default'] = scrimFader;

  function scrimFader() {
    var maxOpacity = 0.5;
    var promises = [];
    if (this.oldElement) {
      promises = promises.concat(Array.from(this.oldElement.find('>div')).map(function (elt) {
        return (0, _liquidFire.animate)((0, _jquery['default'])(elt), { opacity: 0 }, { queue: false, duration: 250 });
      }));
    }
    if (this.newElement) {
      promises = promises.concat(Array.from(this.newElement.find('> div')).map(function (elt) {
        return (0, _liquidFire.animate)((0, _jquery['default'])(elt), { opacity: [maxOpacity, 0] }, { queue: false, duration: 250 });
      }));
    }
    return _rsvp['default'].all(promises);
  }
});