define('ember-overlays/transform', ['exports', 'jquery'], function (exports, _jquery) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _bind = Function.prototype.bind;

  var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

  exports.ownTransform = ownTransform;
  exports.cumulativeTransform = cumulativeTransform;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  /*
    Our Transform type is always respresented relative to
    `transform-origin: 0px 0px`. This is different from the browser's
    own `transform` property, which will vary based on the present value
    of `transform-origin`, and which defaults to `50% 50%`. I am
    standardizing on zero because it disentangles our coordinate system
    from the size of the element, which can vary over time.
  
    Conceptually, each of our Transforms is a 2d affine transformation
    representd as a 3x3 matrix:
  
        a c tx
        b d ty
        0 0  1
  */

  var Transform = (function () {
    function Transform(a, b, c, d, tx, ty) {
      _classCallCheck(this, Transform);

      // WARNING: never mutate an existing Transform. Some of them are
      // shared. Operations need to return new Transforms instead.
      this.a = a;
      this.b = b;
      this.c = c;
      this.d = d;
      this.tx = tx;
      this.ty = ty;
    }

    // WARNING: this constant matrix exists as an optimization. But not
    // every no-op transform triple-equals this value. If you apply two
    // transforms that cancel each other out, you will get an identity
    // matrix but it will not triple-equal this one. And that is OK: we
    // use the triple-equality as an optimization only, not for
    // correctness.
    //
    // The optimization should be worthwhile because the majority of
    // things start out with no preexisting Transform, which we can
    // represent as `identity`, which will make identity.mult(something) a
    // no-nop, etc.

    _createClass(Transform, [{
      key: 'serialize',
      value: function serialize() {
        if (this.isIdentity()) {
          return 'none';
        }
        return 'matrix(' + this.a + ', ' + this.b + ', ' + this.c + ', ' + this.d + ', ' + this.tx + ', ' + this.ty + ')';
      }

      // See the comment below on `const identity`.
    }, {
      key: 'isIdentity',
      value: function isIdentity() {
        return this === identity || this.a === 1 && this.b === 0 && this.c === 0 && this.d === 1 && this.tx === 0 && this.ty === 0;
      }
    }, {
      key: 'mult',
      value: function mult(other) {
        // This is deliberately not isIdentity(). I'm optimizing for the
        // case where there was no preexisting transform at all.
        if (this === identity) {
          return other;
        }
        if (other === identity) {
          return this;
        }
        return new Transform(this.a * other.a + this.c * other.b, this.b * other.a + this.d * other.b, this.a * other.c + this.c * other.d, this.b * other.c + this.d * other.d, this.a * other.tx + this.c * other.ty + this.tx, this.b * other.tx + this.d * other.ty + this.ty);
      }
    }]);

    return Transform;
  })();

  exports['default'] = Transform;
  var identity = new Transform(1, 0, 0, 1, 0, 0);

  exports.identity = identity;
  var matrixPattern = /matrix\((.*)\)/;

  function parseTransform(matrixString) {
    var match = matrixPattern.exec(matrixString);
    if (!match) {
      return identity;
    }
    return new (_bind.apply(Transform, [null].concat(_toConsumableArray(match[1].split(',').map(parseFloat)))))();
  }

  function parseOrigin(originString) {
    return originString.split(' ').map(parseFloat);
  }

  function _ownTransform($elt) {
    var t = $elt.css('transform');
    if (t === 'none') {
      // This constant value is an optimization, and we rely on that in
      // cumulativeTransform
      return identity;
    }
    var matrix = parseTransform(t);
    if (matrix.a !== 1 || matrix.b !== 0 || matrix.c !== 0 || matrix.d !== 1) {
      // If there is any rotation, scaling, or skew we need to do it within the context of transform-origin.

      var _parseOrigin = parseOrigin($elt.css('transform-origin'));

      var _parseOrigin2 = _slicedToArray(_parseOrigin, 2);

      var originX = _parseOrigin2[0];
      var originY = _parseOrigin2[1];

      if (originX === 0 && originY === 0) {
        // transform origin is at 0,0 so it will have no effect, so we're done.
        return matrix;
      }

      return new Transform(1, 0, 0, 1, originX, originY).mult(matrix).mult(new Transform(1, 0, 0, 1, -originX, -originY));
    } else {
      // This case is an optimization for when there is only translation.
      return matrix;
    }
  }

  // I want the public interface for this module to be plain elements,
  // not jQuery, so that we have the option of switching to a non-query
  // implmentation.

  function ownTransform(elt) {
    return _ownTransform((0, _jquery['default'])(elt));
  }

  function cumulativeTransform(elt) {
    var $elt = (0, _jquery['default'])(elt);
    var accumulator = null;
    while ($elt.length > 0 && $elt[0].nodeType === 1) {
      var transform = _ownTransform($elt);
      if (transform !== identity && !transform.isIdentity()) {
        if (accumulator) {
          accumulator = transform.mult(accumulator);
        } else {
          accumulator = transform;
        }
      }
      $elt = $elt.parent();
    }
    return accumulator || identity;
  }
});
/*
  This module's canonical home is:

    https://github.com/ember-animation/ember-animated/blob/master/addon/transform.js

  where it has accompanying tests too. I haven't had a chance to make
  it work as a standalone lib, so we have a copy here for now.

*/