define('ember-overlays/raf', ['exports', 'rsvp'], function (exports, _rsvp) {

  // native promise gives us real request-animation-frame
  // timing. Ember's RSVP uses setTimeout. :-(
  var Promise = window.Promise || _rsvp['default'].Promise;

  exports['default'] = function () {
    var timer = undefined,
        frame = undefined;
    var promise = new Promise(function (resolve) {
      if (typeof requestAnimationFrame === 'undefined') {
        timer = setTimeout(resolve, 33); // 33ms is 30hz
      } else {
          frame = requestAnimationFrame(resolve);
        }
    });
    promise.__ec_cancel__ = function () {
      if (timer != null) {
        clearTimeout(timer);
      } else {
        cancelAnimationFrame(frame);
      }
    };
    return promise;
  };
});
// Cancelable promise-driven requestAnimationFrame with fallbacks.