define('ember-overlays/ember-private-api', ['exports', 'ember'], function (exports, _ember) {
  exports.componentNodes = componentNodes;

  var getViewBounds = undefined;
  if (_ember['default'].ViewUtils && _ember['default'].ViewUtils.getViewBounds) {
    getViewBounds = _ember['default'].ViewUtils.getViewBounds;
  }

  function componentNodes_v113(component) {
    return {
      firstNode: component._renderNode.firstNode,
      lastNode: component._renderNode.lastNode
    };
  }

  function componentNodes_v29(component) {
    var bounds = getViewBounds(component);
    return {
      firstNode: bounds.firstNode,
      lastNode: bounds.lastNode
    };
  }

  // Get the first and last dom nodes for a component (even a tagless
  // one, which is why we need private API).

  function componentNodes(component) {
    if (getViewBounds) {
      return componentNodes_v29(component);
    } else {
      return componentNodes_v113(component);
    }
  }
});
/*
   This module encapsulates all known uses of Ember private APIs. By
   keeping them all together here we make it easier to upgrade Ember.
*/