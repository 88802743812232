define('ember-overlays/components/create-overlay', ['exports', 'ember', 'ember-overlays/templates/components/create-overlay', 'ember-overlays/raf', 'ember-overlays/transform', 'ember-concurrency', 'ember-overlays/bounds', 'ember-overlays/scrim-fader'], function (exports, _ember, _emberOverlaysTemplatesComponentsCreateOverlay, _emberOverlaysRaf, _emberOverlaysTransform, _emberConcurrency, _emberOverlaysBounds, _emberOverlaysScrimFader) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberOverlaysTemplatesComponentsCreateOverlay['default'],
    scrimFader: _emberOverlaysScrimFader['default'],
    classNames: ['show-overlay'],
    classNameBindings: ['reveal', 'hoverable', 'focused'],
    hovered: false,

    // TODO
    fieldScale: 1,

    _targetRect: function _targetRect() {
      var mark = this.get('at');
      return mark.bounds();
    },

    _waitForTargetRect: regeneratorRuntime.mark(function _waitForTargetRect() {
      var hiding, $elt, targetRect;
      return regeneratorRuntime.wrap(function _waitForTargetRect$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            hiding = false;
            $elt = this.$();

          case 2:
            targetRect = this._targetRect();

            if (!targetRect) {
              context$1$0.next = 5;
              break;
            }

            return context$1$0.abrupt('return', targetRect);

          case 5:
            if (!hiding) {
              $elt.css({ display: 'none' });
              hiding = true;
            }
            context$1$0.next = 8;
            return (0, _emberOverlaysRaf['default'])();

          case 8:
            context$1$0.next = 2;
            break;

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, _waitForTargetRect, this);
    }),

    _translation: function _translation(targetRect, ownRect, currentTransform) {
      return 'translateX(' + (targetRect.left - ownRect.left + currentTransform.tx) + 'px) translateY(' + (targetRect.top - ownRect.top + currentTransform.ty) + 'px)';
    },

    _matchWidth: function _matchWidth($elt, targetRect, ownRect) {
      return $elt.outerWidth() + targetRect.right - targetRect.left - ownRect.right + ownRect.left + 'px';
    },

    _matchHeight: function _matchHeight($elt, targetRect, ownRect) {
      return $elt.outerHeight() + targetRect.bottom - targetRect.top - ownRect.bottom + ownRect.top + 'px';
    },

    _track: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var $elt, $ownTarget, lastTargetRect, targetRect, ownRect, t;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            $elt = this.$();
            $ownTarget = this.$('.target');
            lastTargetRect = undefined;

          case 3:
            return context$1$0.delegateYield(this._waitForTargetRect(), 't0', 4);

          case 4:
            targetRect = context$1$0.t0;

            if (!lastTargetRect || !(0, _emberOverlaysBounds.boundsEqual)(targetRect, lastTargetRect)) {
              ownRect = $ownTarget[0].getBoundingClientRect();
              t = (0, _emberOverlaysTransform.ownTransform)($elt[0]);

              $elt.css({
                display: 'initial',
                transform: this._translation(targetRect, ownRect, t) + ' scale(' + this.get('fieldScale') + ')'
              });
              $ownTarget.css({
                width: this._matchWidth($ownTarget, targetRect, ownRect),
                minHeight: this._matchHeight($ownTarget, targetRect, ownRect)
              });
              $elt.find('> label').css({
                transform: 'translateY(-100%) scale(' + 1 / this.get('fieldScale') + ')'
              });
            }
            lastTargetRect = targetRect;
            context$1$0.next = 9;
            return (0, _emberOverlaysRaf['default'])();

          case 9:
            if (!(this.get('lockWhileFocused') && this.get('focused'))) {
              context$1$0.next = 14;
              break;
            }

            context$1$0.next = 12;
            return (0, _emberOverlaysRaf['default'])();

          case 12:
            context$1$0.next = 9;
            break;

          case 14:
            context$1$0.next = 3;
            break;

          case 16:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).on('didInsertElement'),

    reveal: _ember['default'].computed.or('hovered', 'highlighted', 'focused'),

    actions: {
      beginHover: function beginHover() {
        this.set('hovered', true);
      },
      endHover: function endHover() {
        this.set('hovered', false);
      },
      targetClicked: function targetClicked() {
        var handler = this.get('onclick');
        if (handler) {
          handler();
        }
      },
      dismiss: function dismiss() {
        var handler = this.get('dismiss');
        if (handler) {
          handler();
        }
      }
    }
  });
});

// stay hidden until we have a target

// position ourselves over the target