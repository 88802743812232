define('formation-ember-components/mixins/generate-input-id', ['exports', 'npm:node-uuid'], function (exports, _npmNodeUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    inputId: null,

    init: function init() {
      this._super.apply(this, arguments);
      var id = _npmNodeUuid.default.v1();
      this.set('inputId', id);
    }
  });
});