define('formation-ember-components/components/validation-summary/component', ['exports', 'formation-ember-components/components/validation-summary/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      isPresent = Ember.isPresent;
  exports.default = Component.extend({
    classNameBindings: ['isShowing:open'],
    classNames: ['validation-summary'],
    layout: _template.default,
    isShowing: computed('errorMessages.@each', {
      get: function get() {
        var errors = this.get('errorMessages');
        return isPresent(errors);
      },
      set: function set() {
        return;
      }
    }),

    errorMessages: computed('customValidations', 'model.validationErrors.allMessages', function () {
      return this.get('customValidations') || this.get('model.validationErrors.allMessages');
    }),

    actions: {
      close: function close() {
        this.set('isShowing', false);
      }
    }
  });
});