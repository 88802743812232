define('ember-overlays/services/ember-overlays', ['exports', 'ember'], function (exports, _ember) {
  var scheduleOnce = _ember['default'].run.scheduleOnce;
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super();
      this._markSources = Object.create(null);
      this.set('marks', []);
    },

    registerMark: function registerMark(sourceId, markInfo) {
      this._markSources[sourceId] = markInfo;
      this._scheduleUpdate();
    },

    unregisterMark: function unregisterMark(sourceId) {
      this._markSources[sourceId] = null;
      this._scheduleUpdate();
    },

    _scheduleUpdate: function _scheduleUpdate() {
      scheduleOnce('afterRender', this, this._update);
    },

    _update: function _update() {
      var sources = this._markSources;
      var marks = Object.create(null);
      for (var sourceId in sources) {
        var markInfo = sources[sourceId];
        if (markInfo) {
          marks[markInfo.type + '/' + markInfo.id] = markInfo;
        }
      }
      this.set('marks', Object.keys(marks).map(function (k) {
        return marks[k];
      }));
    }

  });
});