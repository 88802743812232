define('formation-ember-components/components/error-detail/component', ['exports', 'formation-ember-components/components/error-detail/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var emberString = Ember.String;
  var capitalize = emberString.capitalize,
      decamelize = emberString.decamelize;
  exports.default = Ember.Component.extend({
    layout: _template.default,
    path: computed('error', function () {
      var error = this.get('error');
      return error && error[0];
    }),

    message: computed('error', function () {
      var error = this.get('error');
      return error[1];
    }),

    // By default an error message will be composed of the the decamelized and
    // capitalized attribute lookup path followed by the message. E.g. the
    // attribute 'repeatPassword' will be rendered as 'Repeat password'. This
    // behaviour can be overriden within the model definition file by adding a
    // mapping of 'validationKeyLabels' with attribute paths to be overidden.
    //
    // Example:
    //
    // validationKeyLabels: {
    //   'ingredientGroups.ingredients.description': 'Ingredient descriptions'
    // }
    //
    // Additionally, a mapping of attributes that should not have the attribute
    // included in the overall message can be provided in a mapping of
    // 'noLabelValidations'.
    //
    // Example:
    //
    // noLabelValidations: {
    //   hed: true
    // }
    attribute: computed('path', 'model.validationKeyLabels', function () {
      var path = this.get('path') || '';
      if (this.get('model.noLabelValidations.' + path)) {
        return;
      }
      var customAttrLabels = this.get('model.validationKeyLabels');
      var customAttr = customAttrLabels && customAttrLabels[path];
      var attribute = void 0;
      if (typeof customAttr === 'string') {
        attribute = customAttr;
      } else {
        attribute = decamelize(path).split('_').join(' ');
      }
      return attribute && capitalize(attribute);
    })

  });
});