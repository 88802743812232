define("ember-overlays/bounds", ["exports"], function (exports) {
  exports.boundsEqual = boundsEqual;
  var tolerance = 1;

  function boundsEqual(a, b) {
    if (a == null && b == null) {
      return true;
    }
    if (!a || !b) {
      return false;
    }
    return Math.abs(a.top - b.top) < tolerance && Math.abs(a.bottom - b.bottom) < tolerance && Math.abs(a.left - b.left) < tolerance && Math.abs(a.right - b.right) < tolerance;
  }
});